////
/// @group components
/// @access public
////

// This file defines castlabs' <fieldset>s.

.cl-form {
  fieldset {
    padding-bottom: $spacing-micro;

    legend {
      @extend %p-form-label;
    }

    &:disabled,
    &.disabled {
      opacity: 0.5;

      // reset disabled element properties so fieldset-opacity does not disable twice
      [type='text'],
      [type='password'],
      [type='url'],
      [type='date'],
      [type='email'],
      [type='number'],
      [type='date'],
      [type='time'],
      [type='datetime-local'],
      textarea,
      select {
        @extend %form-field-box;
        @extend %form-field-typography;

        cursor: not-allowed;
      }

      .form-label,
      .valid-feedback,
      .form-check .form-check-input:disabled ~ .form-check-label {
        color: $color-text;
      }

      [type='checkbox'],
      [type='radio'] {
        + label {
          opacity: 0.5 !important;
        }
      }
    }
  }
}
