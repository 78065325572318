////
/// @group layout
/// @access public
////

// This file defines our basic typography rules.

$typography-font-list-sans:
  'Open Sans',
  -apple-system,
  blinkmacsystemfont,
  'Segoe UI',
  roboto,
  'Helvetica Neue',
  arial,
  sans-serif,
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol';

$typography-font-list-monospace:
  'Noto Sans Mono',
  /* 'Fira Code',*/ sfmono-regular,
  menlo,
  monaco,
  consolas,
  'Liberation Mono',
  'Courier New',
  monospace;

$typography-line-width: 2px;
$typography-font-weight-base: 400;
$typography-font-weight-bold: $typography-font-weight-base + 300;

// --- helpers -----------------------------------------------------------------

@mixin typography-font(
  $font-family: $typography-font-list-sans,
  $font-weight: $typography-font-weight-base,
  $font-size: 1em,
  $line-height: 1.5em,
  $letter-spacing: 0,
  $font-stretch: 100%,
  $force: false
) {
  @if $force {
    @include set-property-if-not(font-stretch, 100%, $font-stretch);

    font-family: $font-family;
    font-size: $font-size;
    font-weight: $font-weight;
    letter-spacing: $letter-spacing;
    line-height: $line-height;
  } @else {
    @include set-property-if-not(font-family, $typography-font-list-sans, $font-family);
    @include set-property-if-not(font-size, 1em, $font-size);
    @include set-property-if-not(letter-spacing, 0, $letter-spacing);
    @include set-property-if-not(font-stretch, 100%, $font-stretch);

    font-weight: $font-weight;
    line-height: $line-height;
  }
}

$typography-links-underline-offset: px(3);

/// Setup links (anchors). Fancy with color, opacity and colorful underlines!
///
/// @example scss
///   @include a-underlined(#333, rgba(#333, 0.7), 2px, #555, rgba(#555, 0.7));
///
/// @param {color} $color [inherit] The text color.
/// @param {color} $color-hover [$color] The text color when hovering.
/// @param {length} $underline-height [1px] The height / thickness of the underline.
/// @param {color} $underline-color [$color] The regular color of the underline.
/// @param {color} $underline-color-hover [$color] The hover color fo the underline.
/// @output An `a {}` rules block for fancy links.
@mixin typography-links(
  $color: inherit,
  $color-hover: $color,
  $underline-height: 1px,
  $underline-color: $color,
  $underline-color-hover: $color
) {
  $gradient-transparent: linear-gradient(transparent, transparent);

  @include color-focus-outline($color, 1px);

  & {
    // https://sass-lang.com/d/mixed-decls
    background-image:
      $gradient-transparent, $gradient-transparent,
      linear-gradient(to right, $underline-color, $underline-color);
    background-position: left bottom $typography-links-underline-offset; // depends on underline
    background-repeat: no-repeat;
    background-size: 100% $underline-height;
    color: $color;
    display: inline;
    padding-bottom: $typography-links-underline-offset;
    text-decoration: none;
  }

  &:hover {
    background-image:
      $gradient-transparent, $gradient-transparent,
      linear-gradient(to right, $underline-color-hover, $underline-color-hover);
    color: $color-hover;
    cursor: pointer;
    text-decoration: none;
  }
}

@mixin typography-links-invisible {
  @include typography-links(inherit, inherit, 0);
  @include color-focus-outline($color-text, 1px);
}

@mixin cl-btn-a($line-height-px) {
  // button styled as regular link
  @extend %cl-a-default;

  border: 0;
  border-radius: 0;
  cursor: pointer;
  font: inherit;
  letter-spacing: inherit;
  line-height: 1.375; // compenate for different browser height
  margin: 0;
  min-width: inherit;
  outline: inherit;
  outline-offset: -2px;
  padding: 0 0 $typography-links-underline-offset;
  text-align: inherit;
  vertical-align: inherit;

  &:hover {
    $gradient-transparent: linear-gradient(transparent, transparent);

    background-image:
      $gradient-transparent, $gradient-transparent,
      linear-gradient(to right, $color-ci-silver, $color-ci-silver);
    color: inherit;
    opacity: inherit;
    text-decoration: inherit;
  }
}

%cl-a-default {
  @include typography-links(
    $color-text,
    $color-text,
    2px,
    rgba($color-ci-silver, 0.35),
    $color-ci-silver
  );
}

%cl-a-primary {
  @include typography-links($color-text, $color-text, 2px, $color-ci-red, $color-ci-silver);
}

%cl-a-secondary {
  @include typography-links($color-text, $color-text, 2px, $color-ci-blue, $color-ci-silver);
}

%cl-a-text {
  @include typography-links($color-text, $color-text, 2px, $color-ci-silver, $color-text);
}

%cl-a-transparent {
  @include typography-links($color-text, $color-text, 2px, transparent, $color-ci-silver);
}

%cl-a-none {
  @include typography-links($color-text, $color-text, 2px, transparent, transparent);
}

%cl-a-btn {
  // a link that is more an embedded button
  @extend %cl-a-text;

  background-color: transparent;
  border: 0;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.025em;
  line-height: 1.5em; // for underline spacing
  margin-top: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

%cl-font-condensed {
  font-stretch: 80%;
  letter-spacing: 0.025em;
}

%h1 {
  @include typography-font($typography-font-list-sans, 400, px(38), px(52));

  color: $color-ci-night;

  @if mixin-exists('media-breakpoint-only') {
    // we might be used without bootstrap
    @include media-breakpoint-only(xs) {
      @include typography-font($typography-font-list-sans, 400, px(26), 1.3em);
    }
  }
}

%h2 {
  @include typography-font($typography-font-list-sans, 500, px(30), 1.25em);

  color: $color-ci-dusk;

  @if mixin-exists('media-breakpoint-only') {
    // we might be used without bootstrap
    @include media-breakpoint-only(xs) {
      @include typography-font($typography-font-list-sans, 400, px(26), 1.3em);
    }
  }
}

%h3 {
  @include typography-font($typography-font-list-sans, 600, px(24), 1.25em);

  color: $color-ci-dusk;

  @if mixin-exists('media-breakpoint-only') {
    // we might be used without bootstrap
    @include media-breakpoint-only(xs) {
      @include typography-font($typography-font-list-sans, 300, px(20), 1.25em);
    }
  }
}

%h4 {
  @include typography-font($typography-font-list-sans, 600, px(22), 1.5em);

  color: $color-ci-dusk;

  @if mixin-exists('media-breakpoint-only') {
    // we might be used without bootstrap
    @include media-breakpoint-only(xs) {
      @include typography-font($typography-font-list-sans, 400, px(16), 1.5em);
    }
  }
}

%p-large {
  @include typography-font($typography-font-list-sans, 300, px(24), px(34));

  color: $color-text;

  b,
  strong {
    font-weight: 500;
  }

  .cl-btn-a {
    @include cl-btn-a(34);
  }

  @if mixin-exists('media-breakpoint-only') {
    // we might be used without bootstrap
    @include media-breakpoint-only(xs) {
      @include typography-font($typography-font-list-sans, 300, px(20), 1.5em);
    }
  }
}

%p-medium {
  @include typography-font($typography-font-list-sans, 400, px(18), 1.5em);

  color: $color-text;

  .cl-btn-a {
    @include cl-btn-a(27);
  }

  @if mixin-exists('media-breakpoint-only') {
    // we might be used without bootstrap
    @include media-breakpoint-only(xs) {
      @include typography-font($typography-font-list-sans, 400, px(16), 1.5em);
    }
  }
}

%p-small {
  @include typography-font($typography-font-list-sans, 400, px(14), 1.5em, 0.02em);

  color: $color-text;

  .cl-btn-a {
    @include cl-btn-a(21);
  }

  @if mixin-exists('media-breakpoint-only') {
    // we might be used without bootstrap
    @include media-breakpoint-only(xs) {
      @include typography-font($typography-font-list-sans, 400, px(12), 1.5em, 0.02em);
    }
  }
}

%p-tiny {
  @include typography-font($typography-font-list-sans, 700, px(10), 1.2em, 0.1em);

  color: $color-text;
  text-transform: uppercase;

  @if mixin-exists('media-breakpoint-only') {
    // we might be used without bootstrap
    @include media-breakpoint-only(xs) {
      @include typography-font($typography-font-list-sans, 700, px(8), 1.2em, 0.1em);
    }
  }
}

%p-micro {
  @include typography-font($typography-font-list-sans, 600, px(8), 1.2em, 0.1em);

  color: $color-text;
  font-weight: 600;
}

%p-nav {
  // for nav entries, button labels etc.
  @include typography-font($typography-font-list-sans, 600, px(16), 1em, 0.03125em);

  color: $color-text;
  font-weight: 600;
}

%p-pre {
  @include typography-font($typography-font-list-monospace, 400, px(16), 1.5em, 0.025em, 80%);

  background-color: transparent;
  color: $color-ci-dim;
  font-stretch: 80%;
  font-variant-ligatures: none;

  @if mixin-exists('media-breakpoint-only') {
    // we might be used without bootstrap
    @include media-breakpoint-only(xs) {
      @include typography-font($typography-font-list-monospace, 400, px(14), 1.5em, 0.025em, 80%);
    }
  }
}

%p-label {
  @extend %p-tiny;

  color: $color-ci-blue;
}

%p-form-label {
  @extend %p-medium;

  color: $color-ci-dim;
  font-weight: 600;
  letter-spacing: normal;
  text-transform: none;
}
