////
/// @group components
/// @access public
////

// This file defines how the stuff inside the sidenav drawer looks like.

$sidenav-background: $color-ci-dim;
$sidenav-color-text: $color-ci-dusk;
$sidenav-color-background: $color-ci-haze;

@mixin cl-nav-fadeout($color) {
  overflow: hidden;
  position: relative;
  white-space: nowrap;

  &::after {
    // fade-out gradient
    background-image: linear-gradient(to right, transparent, $color);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 1rem;
  }
}

.cl-nav-side {
  // --- drawer ----------------------------------------------------------------

  .cl-nav-drawer {
    background-color: $sidenav-color-background;
    color: $sidenav-color-text;
  }

  // --- meta/common -----------------------------------------------------------

  .cl-label {
    @extend %p-tiny;
    @extend %cl-grid-flex-centered;

    height: 2.5rem;
    margin-top: 0;
    padding: 0 $spacing-small;
    position: relative;

    label {
      margin-top: $spacing-small;
    }

    .btn {
      position: absolute;
      right: $spacing-small;
      top: 0.9rem;
    }
  }

  .cl-nav-user {
    // ------------------------------------------------------------
    @extend %p-nav;

    $background: $color-ci-dusk;
    $foreground: $color-ci-white;

    align-items: center;
    background-color: $background;
    border-bottom: 0;
    color: $foreground;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: $header-nav-height;
    padding: 0 $spacing-small;

    a {
      @include typography-links($foreground, $foreground, 0);
      @include color-focus-outline($foreground);

      & {
        padding-bottom: 0;
      }

      &:first-of-type {
        // the username
        @include cl-nav-fadeout($background);

        & {
          margin-right: $spacing-micro;
          margin-top: -0.25em;
          width: 100%;
        }

        &::before {
          // user icon
          @include cl-fontawesome('\f2bd');

          & {
            color: $color-ci-haze;
            font-size: px(22);
            line-height: 1.25;
            margin-right: $spacing-tiny;
          }
        }
      }
    }

    .cl-nav-icon {
      flex-shrink: 0;
      font-size: px(22);
    }
  }

  div.cl-nav-organization,
  a.cl-nav-organization,
  details.cl-nav-organization {
    #{'--element-fg-color'}: $color-ci-white;
    #{'--element-bg-color'}: $color-ci-dim;
    background-color: var(--element-bg-color);
    color: var(--element-fg-color);
    display: block;
    overflow: hidden;
    text-decoration: none;

    label {
      @extend %p-tiny;

      color: var(--element-fg-color);
    }

    .cl-nav-organization-name {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      min-height: 42px;
      overflow: hidden;
      white-space: nowrap;

      p {
        font-size: px(16);
        font-weight: 600;
        letter-spacing: 0.03125em;
        margin-left: $spacing-medium + $spacing-micro;
        margin-top: 0;

        &:first-of-type {
          position: relative;

          &::before {
            // globe icon
            @include cl-fontawesome('\f0ac');

            & {
              display: block;
              font-size: 22px;
              left: calc(-24px - $spacing-micro);
              position: absolute;
            }
          }
        }

        + p {
          // parent org
          @extend %p-small;

          color: $color-ci-silver;
          letter-spacing: 0;
          line-height: 1.4; // avoids cutoff g/y
          margin-top: -2px; // avoids cutoff g/y
        }
      }
    }
  }

  details.cl-nav-organization summary,
  div.cl-nav-organization,
  a.cl-nav-organization {
    height: $header-headline-height;
    padding: 0 $spacing-small;
    position: relative;

    &::after {
      @include cl-fontawesome('\f078'); // chevron-down

      & {
        align-items: center;
        background-color: var(--element-bg-color);
        box-shadow: -8px 0 4px var(--element-bg-color);
        color: $color-ci-silver;
        display: flex;
        height: calc(100% - 4px);
        justify-content: flex-start;
        position: absolute;
        right: 4px;
        top: 2px;
        width: calc($spacing-small * 2 - 2px);
      }
    }

    &.active {
      &::after {
        content: '\f054'; // chevron-right
      }
    }
  }

  a.cl-nav-organization {
    @include color-focus-outline($color-ci-white);
  }

  details.cl-nav-organization {
    summary {
      @include color-focus-outline(var(--element-fg-color));
    }

    &[open] {
      padding-bottom: $spacing-tiny;

      summary {
        border-bottom: 2px solid $color-white-25a;
        margin-bottom: $spacing-tiny;
      }

      summary::after {
        content: '\f077'; // chevron-up
      }
    }

    .cl-nav-item,
    .cl-dropdown-item {
      @include color-focus-outline(var(--element-fg-color));

      & {
        background-color: var(--element-bg-color);
        color: var(--element-fg-color);
        line-height: 1;
        padding: $spacing-small;
      }

      &.active {
        #{'--element-fg-color'}: $color-ci-dim;
        #{'--element-bg-color'}: $color-ci-haze;

        &::after {
          // we use a block item, not a border, so content does not jump
          background-color: $color-ci-silver;
          content: '';
          display: block;
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          width: $bar-ui-width;
        }
      }

      &:hover:not(.active) {
        background-color: $color-ci-dusk;
      }
    }

    > summary::marker,
    > summary::-webkit-details-marker {
      content: '';
      display: none;
    }
  }

  .cl-nav-site {
    // --- red site/product switcher ------------------------------
    @extend %p-nav;

    $background: $color-ci-red;
    $foreground: $color-ci-white;

    @include color-focus-outline($foreground);

    & {
      background-color: $background;
      color: $foreground;
      margin-bottom: $spacing-small;
      margin-top: 0;
    }

    .dropdown-toggle {
      border: 0;
      color: $foreground;
      font-size: px(18);
      height: 4rem;
      text-align: start;
      width: 100%;

      &:focus {
        box-shadow: none;
      }
    }

    .dropdown-menu {
      background-color: $color-ci-red;
      border: 0;
      border-radius: 0;
      box-shadow: 0 4px 0 $color-black-25a; // downward
      width: 100%;
    }

    li {
      display: flex;
      flex-direction: column;
      height: 4rem;
      justify-content: center;
      overflow: hidden;
    }

    .dropdown-item {
      @extend %p-nav;

      @include typography-links($color-ci-white, $color-ci-red, 0);

      & {
        padding: 0.75em $spacing-small;
        white-space: nowrap;
      }
    }
  }

  .cl-nav-label {
    // -----------------------------------------------------------
    @extend %p-label;

    color: inherit !important; // sass-lint:disable-line no-important
    margin-bottom: $spacing-tiny;
    margin-left: $spacing-small;
  }

  .cl-nav-item {
    // ------------------------------------------------------------
    @extend %p-nav;

    @include typography-links($sidenav-color-text, $sidenav-color-text, 0);
    @include color-focus-outline($sidenav-color-text);

    & {
      display: inline-block;
      line-height: 1.5em;
      padding: $spacing-tiny $spacing-small;
      position: relative;
      width: 100%;
    }

    .cl-nav-item-info {
      @extend %p-small;

      color: $color-ci-dim;
      line-height: 1.4em;
      margin-top: px(3);

      + .cl-nav-item-info {
        margin-top: $spacing-micro;
      }
    }

    > [class^='cl-badge'] {
      position: absolute;
      right: $spacing-small;
      top: $spacing-tiny + $spacing-micro;
    }

    &:hover:not(.active) {
      background-color: $color-ci-white;
    }

    &.active {
      @include typography-links($color-ci-night, $color-ci-night, 0);

      & {
        background-color: $color-ci-white;
        color: $color-ci-red;
        display: inline-block;
        padding: $spacing-tiny $spacing-small;
        position: relative;
      }

      &::after {
        // we use a block item, not a border, so content does not jump
        background-color: $color-ci-silver;
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: $bar-ui-width;
      }
    }

    .btn {
      position: absolute;
      right: $spacing-small;
      top: 1.25em;
    }
  }

  .cl-dropdown-dim,
  .cl-dropdown-dark {
    .active {
      background-color: $color-ci-haze;
      color: $color-ci-dim;

      &::after {
        // we use a block item, not a border, so content does not jump
        background-color: $color-ci-silver;
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: $bar-ui-width;
      }
    }
  }

  .cl-dropdown + .cl-nav-item {
    margin-top: $spacing-small;
  }

  .cl-nav-separator {
    // -------------------------------------------------------
    background-color: $color-ci-smoke;
    border: 0;
    height: px(2);
    margin: $spacing-small 0;
    opacity: 1;

    &:not([size]) {
      height: px(2);
    }
  }
}

.cl-nav-item-lock,
.cl-nav-item-locked {
  span:first-of-type::after {
    @include cl-fontawesome('\f023');

    & {
      margin-left: $spacing-tiny;
      opacity: 0.5;
      top: inherit;
    }
  }
}

.cl-nav-item-locked {
  opacity: 0.5;

  &:hover {
    cursor: not-allowed;
  }
}
