////
/// @group components
/// @access public
////

// This file defines a type of section with a 8-4 layout (main + side area)

.cl-section-main-side {
  padding-top: 0;

  // .cl-section-primary + & { // first one right below header
  //   padding-top: $spacing-small;
  // }

  .row > [class^='col-'] {
    margin-top: $spacing-small * 2;
  }

  @include media-breakpoint-up(lg) {
    .col-lg-8 {
      padding-right: $spacing-medium;
    }
  }

  @include media-breakpoint-up(xxl) {
    .col-lg-8 {
      padding-right: $spacing-large;
    }
  }

  .cl-spacing-side {
    // to align stuff in the side area in full mode
    display: none;
    visibility: hidden;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
}
