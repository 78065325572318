.cl-form {
  // --- field invalidation ----------------------------------------------------

  :invalid {
    // remove HTML5 browser styling
    box-shadow: none; // remove browser shading
  }

  [invalid],
  .invalid {
    border-color: $color-ci-blue;
    color: $color-ci-blue;

    + * + .invalid-feedback,
    + .invalid-feedback {
      display: block;
    }

    + * + .valid-feedback,
    + .valid-feedback {
      display: none;
    }
  }

  // --- disabled fields -------------------------------------------------------

  .form-label.disabled,
  .valid-feedback.disabled {
    color: $color-ci-silver;
  }

  textarea[disabled],
  input[disabled],
  select[disabled] {
    color: $form-color-disabled;
    cursor: not-allowed;
  }

  select[disabled] {
    --bs-form-select-bg-img: url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3E%3Cpath fill=%27none%27 stroke=%27%23B0B3B6%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3E%3C/svg%3E');
  }
}
