////
/// @group components
/// @access public
////

// This file define our (bullet) lists. They use fontawesome icons as bullets.

$list-indent: $spacing-small * 2.5;

%cl-list {
  position: relative;

  li {
    list-style-type: none;

    &::before {
      @include cl-fontawesome('\f054'); // chevron

      & {
        color: $color-ci-silver;
        margin-left: -$list-indent;
        position: absolute;
        text-align: center;
        top: inherit;
        transform: translateY(0.2em);
        width: $list-indent;
      }
    }

    > ul {
      margin-top: $spacing-tiny;
    }
  }

  &-none {
    margin-left: 0;

    li {
      margin-top: $spacing-micro;
    }
  }

  &-none li::before,
  li.cl-list-none::before {
    display: none;
  }

  &-check li::before,
  li.cl-list-check::before {
    content: '\f00c';
  }

  &-x li::before,
  li.cl-list-x::before {
    content: '\f00d';
  }

  &-plus li::before,
  li.cl-list-plus::before {
    content: '\f067';
  }

  &-minus li::before,
  li.cl-list-minus::before {
    content: '\f068';
  }

  &-lock li::before,
  li.cl-list-lock::before {
    content: '\f023';
  }

  &-bulb li::before,
  li.cl-list-bulb::before {
    content: '\f0eb';
  }

  &-spinner li::before,
  li.cl-list-spinner::before {
    animation: cl-spin 2s linear infinite;
    content: '\f110';
    margin-top: $spacing-micro;
  }
}

// -----------------------------------------------------------------------------

$list-dt-width: 14rem;

%cl-dl {
  dt {
    @extend %p-form-label;

    display: block;
  }

  dd {
    display: block;
    margin-top: $spacing-tiny;
  }

  @include media-breakpoint-up(md) {
    dt {
      display: inline-block;
      vertical-align: top;
      width: $list-dt-width;
    }

    dd {
      display: inline-block;
      margin-top: $spacing-small;
      width: calc(100% - #{$list-dt-width} - 0.5rem);

      &:first-of-type {
        margin-top: 0;
      }

      + dd {
        margin-left: $list-dt-width;
        margin-top: $spacing-tiny;
      }
    }
  }

  &.cl-dl-tight {
    @include media-breakpoint-up(md) {
      dt {
        margin-top: $spacing-tiny;

        &:first-of-type {
          margin-top: 0;
        }
      }

      dd {
        margin-top: $spacing-tiny;

        &:first-of-type {
          margin-top: 0;
        }
      }
    }
  }
}
