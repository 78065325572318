.cl-table-data {
  .cl-td-boolean {
    text-align: center;
    word-wrap: unset;

    @include media-breakpoint-down($table-responsive) {
      table:not(.cl-table-nonresponsive) & {
        text-align: left;
      }
    }

    .fa-minus::before {
      color: $color-ci-silver;
    }
  }
}
