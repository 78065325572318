////
/// @group layout
/// @access public
////

// This file defines our page grid. It is mostly based on bootstrap but with a few tweaks.

@import 'bootstrap/scss/bootstrap-grid';
@import 'grid.variables';

// bootstrap uses a narrow container for sm, but we prefer full-width instead
@include media-breakpoint-only(sm) {
  .container {
    max-width: inherit;
  }
}

.container .container {
  // nested containers should not limit themselves
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
}

// -----------------------------------------------------------------------------

// We remove mandatory paddings from .container's
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  padding-left: 0;
  padding-right: 0;

  > .row {
    margin-left: 0;
    margin-right: 0;
  }
}

// alignment classes for row content
.row {
  &.text-start {
    justify-content: flex-start;
  }

  &.text-center {
    justify-content: center;
  }

  &.text-end {
    justify-content: flex-end;
  }
}

// do pull out nested containers or their cel padding will indent them
.container .container > .row {
  margin-left: -$spacing-tiny;
  margin-right: -$spacing-tiny;
}

// sections with slim content width limit the main container
.cl-section-slim > .container {
  max-width: 848px;
}

// -----------------------------------------------------------------------------

$grid-spacing: $spacing-small;

section {
  padding-bottom: $spacing-small;
  padding-top: 0; // first element usually will add the rest.

  @include media-breakpoint-up(sm) {
    padding-bottom: $spacing-large;
    padding-top: $spacing-medium; // first element usually will add the rest.
  }
}

main {
  background-color: $color-ci-white;
  display: table-row;
  height: 100%;

  .row {
    #{'--bs-gutter-x'}: $grid-spacing;

    > [class^='col-'] {
      margin-top: $grid-spacing; // columns add top padding as first content line won't
    }
  }
}

.cl-flex-center-center {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.cl-faded {
  opacity: 0.5;
}

h1.cl-faded,
.cl-h1.cl-faded,
h2.cl-faded,
.cl-h2.cl-faded,
h3.cl-faded,
.cl-h3.cl-faded,
h4.cl-faded,
.cl-h4.cl-faded,
h5.cl-faded,
.cl-h5.cl-faded,
h6.cl-faded,
.cl-h6.cl-faded {
  opacity: 0.3;
}

// -----------------------------------------------------------------------------
img {
  max-width: 100%;
}

html,
body,
.cl-app {
  @include typography-font($typography-font-list-sans, 400, px(18), px(27), 0, 100, true);

  background-color: $color-ci-white;
  height: 100%;
}

.cl-main {
  display: table;
  height: 100%;
  table-layout: fixed;
  width: 100%;

  > *,
  main > * {
    width: 100%;
  }
}
