////
/// @group layout
/// @access public
////

// This file defines how we structure pages with <section class="cl-section-regular">s.

%section-padding {
  padding-left: $spacing-tiny;
  padding-right: $spacing-tiny;

  @include media-breakpoint-up(sm) {
    padding-left: $spacing-medium;
    padding-right: $spacing-medium;
  }
}

.cl-section-primary,
.cl-section-secondary,
.cl-section-dark,
.cl-section-light,
.cl-section-regular,
.cl-section-alternate {
  @extend %section-padding;
}

// two successive sections of the same bg color loose a bit of gap
.cl-section-regular + .cl-section-regular,
.cl-section-alternate + .cl-section-alternate,
.cl-section-dark + .cl-section-dark,
.cl-section-light + .cl-section-light,
.cl-section-primary + .cl-section-primary,
.cl-section-secondary + .cl-section-secondary {
  @include media-breakpoint-up(sm) {
    margin-top: $spacing-small * -3;
  }
}

.cl-section-primary,
.cl-section-secondary,
.cl-section-dark {
  color: $color-white-100;

  h1,
  h2,
  h3 {
    color: $color-white-100;
  }

  a {
    @include typography-links(
      $color-ci-white,
      rgba($color-white-80a, $color-hover-opacity),
      2px,
      $color-ci-white,
      rgba($color-white-80a, $color-hover-opacity)
    );
  }
}

.cl-section-primary {
  background-color: $color-primary;

  @include media-breakpoint-up(sm) {
    min-height: px(88);
  }
}

.cl-section-secondary {
  background-color: $color-secondary;
}

.cl-section-dark {
  background-color: $color-dark;
}

.cl-section-light,
.cl-section-alternate {
  background-color: $color-ci-haze;
}

.cl-section-tight {
  // deprecated
  padding-bottom: 1rem;
  padding-top: 0;
}

main {
  > .cl-section-regular:last-child {
    padding-bottom: $grid-spacing * 3;
  }
}
