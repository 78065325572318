.cl-spinner {
  @extend %p-medium;
  @extend %spacing-small;

  animation-duration: 1s;
  animation-name: cl-spinner-fadein;

  .fa-spinner {
    margin-left: $spacing-tiny;
    margin-right: $spacing-tiny;
  }
}

@keyframes cl-spinner-fadein {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes cl-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
