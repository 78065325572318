////
/// @group global
/// @access public
////

// This file is the root for this lib's stylesheet and will include all others.

// Hint: Component-specific rules go into the vue components folders impored below.

@use 'sass:math';

$castlabs-ui-asset-prefix: '..' !default;

@import 'bootstrap/scss/bootstrap-reboot';
@import 'bootstrap/scss/helpers/ratio';
@import 'global';
@import 'assets/logo';
@import 'layout/spacing';
@import 'layout/grid';
@import 'layout/typography';
@import 'layout/section';
@import 'layout/animation';

// Vue components
@import '../components/ClAlert/style';
@import '../components/ClBadge/style';
@import '../components/ClButton/style';
@import '../components/ClCard/style';
@import '../components/ClDropdown/style';
@import '../components/ClDropzone/style';
@import '../components/ClFooter/style';
@import '../components/ClIcon/style';
@import '../components/ClIconotron/style';
@import '../components/ClList/style';
@import '../components/ClListGroup/style';
@import '../components/ClPagination/style';
@import '../components/ClProgress/style';
@import '../components/ClScrollbar/style';
@import '../components/ClSpinner/style';
@import '../components/ClTabs/style';
@import '../components/ClToggle/style';
@import '../components/ClTooltip/style';
@import '../components/ClWizard/style';
@import '../components/form/ClForm/style';
@import '../components/form/ClField/style';
@import '../components/form/ClFieldCheck/style';
@import '../components/form/ClFieldFile/style';
@import '../components/form/ClFieldInput/style';
@import '../components/form/ClFieldSelect/style';
@import '../components/form/ClFieldSet/style';
@import '../components/form/ClFieldGroup/style';
@import '../components/modal/ClModal/style';
@import '../components/navigation/ClNavSide/style';
@import '../components/navigation/ClNavSide/ClNavDrawer/style';
@import '../components/navigation/ClNavSide/ClNavSideMenu/style';
@import '../components/navigation/ClNavTop/style';
@import '../components/section/ClSectionForm/style';
@import '../components/section/ClSectionHeadline/style';
@import '../components/section/ClSectionMainSide/style';
@import '../components/table/ClTable/style';
@import '../components/table/ClTableFootChecked/style';
@import '../components/table/ClTableHead/style';
@import '../components/table/ClTableCel/style';
@import '../components/table/ClTableCel/Actions/style';
@import '../components/table/ClTableCel/Audit/style';
@import '../components/table/ClTableCel/Boolean/style';
@import '../components/table/ClTableCel/Checkbox/style';
@import '../components/table/ClTableCel/Code/style';
@import '../components/table/ClTableCel/Currency/style';
@import '../components/table/ClTableCel/Date/style';
@import '../components/table/ClTableCel/ID/style';
@import '../components/table/ClTableCel/Links/style';
@import '../components/text/ClCopy/style';
@import '../components/text/ClLinkExternal/style';
@import '../components/text/ClOrg/style';
@import '../components/text/ClPlan/style';
@import '../components/text/ClRole/style';
@import '../components/text/ClHashtag/style';
@import '../components/widget/ClBackCancelOk/style';
@import '../components/widget/ClCookieBanner/style';
@import '../components/widget/ClPage/style';

// --- globals -----------------------------------------------------------------

.cl-shadow {
  @include color-shadow-3d;
}

.cl-hidden {
  visibility: hidden;
}

.cl-cursor-link {
  cursor: pointer;
}

.cl-cursor-not-allowed {
  cursor: not-allowed;
}
