////
/// @group components
/// @access public
////

// This file defines castlabs' <form>s & labels (both titles and info/error texts).

.cl-form {
  // --- basic form layout -----------------------------------------------------

  // &.container,
  .container {
    @extend %container-spacing-small;
  }

  .container + * {
    margin-top: 0; // container already add bottom spacing
  }

  .row > [class^='col-'] {
    margin-top: $spacing-small;
  }

  > :first-child {
    margin-top: 0;
  }

  // --- input labels ----------------------------------------------------------

  label {
    display: inline-block;
  }

  .form-label {
    @extend %p-form-label;

    .required {
      color: $color-attention;
      font-weight: 800;
      margin-left: $spacing-micro;
    }

    + * {
      margin-top: $spacing-micro;
    }
  }

  // --- info/error text below the input fields --------------------------------

  $form-info-indent: 1.25em;

  .valid-feedback,
  .invalid-feedback {
    @extend %p-small;

    margin-left: $form-info-indent;
    margin-top: $spacing-micro;

    &::before {
      color: $color-ci-silver;
      content: '\f05a';
      display: inline-block;
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      margin-left: -$form-info-indent;
      width: $form-info-indent;
    }
  }

  .invalid-feedback {
    color: $color-ci-blue;
    display: none;

    &::before {
      color: $color-ci-blue;
      content: '\f057';
    }
  }

  .valid-feedback.valid-feedback-none {
    min-height: 1.5em; // line-height of %p-small

    &::before {
      display: none;
    }
  }
}
