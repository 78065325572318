////
/// @group components
/// @access public
////

// A custom dropdown / drawer element based on <detail> and <summary>. No JS needed.

$dropdown-border: 0.125rem; // a 2px border to add so there is space for the outline
$dropdown-color-chevron: rgba($color-ci-white, 0.5);
$dropdown-color-chevron-bright: rgba($color-ci-silver, 0.5);
$dropdown-line-height: 1.25em;

.cl-dropdown {
  #{'--element-fg-color'}: $color-text;
  #{'--element-bg-color'}: $color-ci-haze;
  background-color: var(--element-bg-color);
  color: var(--element-fg-color);
  position: relative;

  > * {
    margin-left: $spacing-small;
    margin-right: $spacing-small;
  }

  summary {
    @extend %p-nav;

    @include color-focus-outline(var(--element-fg-color));

    & {
      border: $dropdown-border solid var(--element-bg-color); // border need so that outline is not behind ::after
      color: var(--element-fg-color);
      font-size: px(18);
      list-style: none;
      margin-left: 0;
      margin-right: 0;
      overflow: hidden;
      padding: $spacing-small - $dropdown-border $spacing-small - $dropdown-border;
      padding-right: $spacing-small * 3; // for chevron
      position: relative;
      white-space: preserve;
    }

    &::after {
      @include cl-fontawesome('\f078'); // chevron-down

      & {
        align-items: center;
        background-color: var(--element-bg-color);
        box-shadow: -8px 0 4px var(--element-bg-color);
        color: $dropdown-color-chevron-bright;
        display: flex;
        height: 100%;
        justify-content: flex-start;
        position: absolute;
        right: 0;
        top: 0;
        width: $spacing-small * 2;
      }
    }

    > [class^='cl-badge'] {
      position: absolute;
      right: $spacing-small;
      top: $spacing-tiny + $spacing-micro;
      z-index: 1;
    }
  }

  > summary::marker,
  > summary::-webkit-details-marker {
    display: none;
  }

  &[open] {
    padding-bottom: $spacing-tiny;

    summary {
      border-bottom: 2px solid $color-ci-white;
      margin-bottom: $spacing-tiny;

      &::after {
        content: '\f077'; // chevron-up
      }
    }
  }

  .cl-subentry {
    font-size: px(14);
    font-weight: 400;
    letter-spacing: 0;
    margin-top: px(5);
    opacity: $color-hover-opacity;
  }

  &-tiny {
    summary {
      padding: $spacing-tiny - $dropdown-border $spacing-small - $dropdown-border;
    }
  }

  &-small {
    summary {
      padding: $spacing-small - $dropdown-border;
    }
  }

  &-medium {
    summary {
      padding: $spacing-medium - $dropdown-border $spacing-small - $dropdown-border;
    }
  }

  &-empty {
    // disable dropdown part if empty
    pointer-events: none;

    summary {
      border: 0 !important; // sass-lint:disable-line no-important

      &::after {
        display: none;
      }
    }

    &[open] {
      padding-bottom: 0;

      summary {
        margin-bottom: 0;
      }
    }
  }
}

div.cl-dropdown summary {
  // hide the chevron if this is not a <detail>
  pointer-events: none;

  &::after {
    display: none;
  }
}

.cl-dropdown-arrow {
  background-color: $color-ci-haze;
  content: '';
  display: inline-block;
  height: 1rem;
  left: $spacing-small;
  margin-top: -$spacing-tiny;
  position: absolute;
  transform: rotate(45deg);
  width: 1rem;
  z-index: -1;

  + .cl-nav-item {
    margin-top: $spacing-small;
  }

  .cl-dropdown-primary + & {
    background-color: $color-primary;
  }

  .cl-dropdown-secondary + & {
    background-color: $color-secondary;
  }

  .cl-dropdown-dark + & {
    background-color: $color-dark;
  }

  .cl-dropdown-dim + & {
    background-color: $color-ci-dim;
  }
}

.cl-dropdown-item {
  @extend %p-nav;

  @include typography-links(var(--element-fg-color), var(--element-fg-color), 0);
  @include color-focus-outline(var(--element-fg-color));

  & {
    display: block;
    line-height: $dropdown-line-height;
    margin-left: 0;
    margin-right: 0;
    padding: $spacing-tiny + $spacing-micro $spacing-small;
    position: relative;
    white-space: preserve;
    width: 100%;
  }

  &:hover {
    background-color: $color-ci-white;
  }

  .cl-subentry {
    font-size: px(14);
    font-weight: 400;
    letter-spacing: 0;
    margin-top: px(5);
    opacity: $color-hover-opacity;
  }

  > [class^='cl-badge'] {
    position: absolute;
    right: $spacing-small;
    top: $spacing-tiny + $spacing-micro;
  }
}

.cl-dropdown-primary {
  #{'--element-bg-color'}: $color-primary;
  #{'--element-fg-color'}: $color-ci-white;

  summary::after {
    color: $dropdown-color-chevron;
  }

  &[open] summary {
    border-bottom: 2px solid $color-white-35a;
  }

  .cl-nav-separator {
    background-color: $color-white-35a !important; // sass-lint:disable-line no-important
  }

  .cl-dropdown-item:hover:not(.active) {
    color: $color-ci-dusk;
  }
}

.cl-dropdown-secondary {
  #{'--element-bg-color'}: $color-secondary;
  #{'--element-fg-color'}: $color-ci-white;

  summary::after {
    color: $dropdown-color-chevron;
  }

  &[open] summary {
    border-bottom: 2px solid $color-white-35a;
  }

  .cl-nav-separator {
    background-color: $color-white-35a !important; // sass-lint:disable-line no-important
  }

  .cl-dropdown-item:hover:not(.active) {
    color: $color-ci-dusk;
  }
}

.cl-dropdown-dark {
  #{'--element-bg-color'}: $color-dark;
  #{'--element-fg-color'}: $color-ci-white;

  summary::after {
    color: $dropdown-color-chevron;
  }

  &[open] summary {
    border-bottom: 2px solid $color-white-25a;
  }

  .cl-nav-separator {
    background-color: $color-white-25a !important; // sass-lint:disable-line no-important
  }

  .cl-dropdown-item:hover:not(.active) {
    background-color: $color-ci-dusk;
  }
}

.cl-dropdown-dim {
  #{'--element-bg-color'}: $color-ci-dim;
  #{'--element-fg-color'}: $color-ci-white;

  summary::after {
    color: $dropdown-color-chevron;
  }

  &[open] summary {
    border-bottom: 2px solid $color-white-25a;
  }

  .cl-nav-separator {
    background-color: $color-white-25a !important; // sass-lint:disable-line no-important
  }

  .cl-dropdown-item:hover:not(.active) {
    background-color: $color-ci-dusk;
  }
}
