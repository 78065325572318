/* You can add global styles to this file, and also import other style files */
@use 'sass:math';

.cdk-overlay-container {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000; /* Ensure it's above Bootstrap */
}

html, body {
  height: 100%;
}

body {
  margin: 0;
}


$castlabs-ui-asset-prefix: '../..' !default;

@import '@castlabs/ui/src/styles/ui';

.cl-form .invalid-feedback {
  display: unset;
}
